import React, { useState } from 'react'
import './SendEmail.scss'
import { useNavigate } from 'react-router-dom'
import { axiosClient } from '../../utils/axiosClient';
import Navbar from '../navbar/Navbar';
import ReactDOMServer from 'react-dom/server';
import Mailbody from '../../componets/mailbody/Mailbody';

function SendEmail() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate();

    function renderMailBody() {
        return ReactDOMServer.renderToStaticMarkup(<Mailbody />);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        setError('')
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            if (!email) {
                setError('Emai is required')
                return;
            }

            const mailBodyHtml = renderMailBody();

            await axiosClient.post('/send/sendEmail', {
                email,
                htmlBody: mailBodyHtml
                // htmlBody: '<h1> this is mail body<h1/>'
            });
            // console.log('Axios Result!!!:', result);
            // console.log('Mail sent');
            navigate('/success')
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='SendEmail'>
            <Navbar showAdminButton={true} />
            <div className="email-container">
                <h1 className="main-heading">Login</h1>
                <h4>Please Login to your account</h4>
                <form onSubmit={handleSubmit} className='email-form'>
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input type="email" className="email-input" id="email" name="email" onChange={handleEmailChange} />
                        <div className="error-container">
                            {error && <p className='error-message'>{error}</p>}
                        </div>
                    </div>
                </form>
                <button onClick={handleSubmit} type="button" className="btn btn-primary">Submit</button>
            </div>
        </div>
    )
}

export default SendEmail