import React, { useEffect, useState } from 'react';
import { axiosClient } from '../../utils/axiosClient';
import './Admin.scss';
import { MdDeleteOutline } from 'react-icons/md'
import { AiOutlineLogout } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/authSlice';
import companyLogo from '../../assets/final logo_cableSmith_Horizontal_PNG.png'
import squreLogo from '../../assets/finallogo.png'
import DownloadExcel from '../../componets/downloadExcel/DownloadExcel';
// import { useNavigate } from 'react-router-dom'

function Admin() {
    const [emails, setEmails] = useState([]);
    const dispatch = useDispatch();
    // const navigate = useNavigate()

    async function getEmail() {
        try {
            const response = await axiosClient.get('/user/getEmail');
            setEmails(response.data.result.emails);
            // console.log({ response });
            // console.log(response.data.result.emails);
        } catch (error) {
            console.log('Error fetching emails:', error);
        }
    }

    function handleClick() {
        dispatch(logoutUser(false))
    }

    useEffect(() => {
        getEmail();
    }, []);

    const deleteEmail = async (emailId) => {
        try {
            await axiosClient.delete(`/user/delete/${emailId}`);
            // Update state after successful deletion
            setEmails(emails.filter((email) => email._id !== emailId));
        } catch (error) {
            console.log('Error deleting email:', error);
        }
    };

    return (
        <div className='Admin'>
            <div className='header'>
                <div className="nav-container">
                    <div className="logo-container">
                        <div className="img-box">
                            <img className='horizontal-logo' src={companyLogo} alt="Cablesmith" />
                        </div>

                        <div className="img-box">
                            <img className='square-logo' src={squreLogo} alt="Cablesmith" />
                        </div>
                    </div>
                    <div className="left-part">
                        <div className="download-excel">
                            <DownloadExcel />
                        </div>
                        <div onClick={handleClick} className="btn-logout">
                            <AiOutlineLogout className='btn-logo' />
                            <p className='btn-text'>logout</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin-login-section">
                <h1 className='main-heading'>Download Emails in Excel Admin</h1>
                <table className='email-table'>
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th className='email-heading'>Emails</th>
                            <th className='createdAt-heading'>Time</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(emails) &&
                            emails
                                .slice()
                                .reverse()
                                .map((email, index) => (
                                    <tr className='email-row' key={email._id}>
                                        <td>{index + 1}</td>
                                        <td className='user-email'>{email.email}</td>
                                        <td className='createdAt'>
                                            {new Date(email.createdAt).toLocaleTimeString()} -{' '}
                                            {new Date(email.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className='remove-user' onClick={() => deleteEmail(email._id)}><MdDeleteOutline className='delete-icon' /></td>
                                    </tr>
                                ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Admin;
