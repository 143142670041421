import React from 'react';

function Mailbody() {

    const listStyle = {
        listStyle: 'none',
        marginLeft: '20px'
    }

    const liStyle = {
        paddingTop: '1rem'
    }

    const pStyle = {
        color: '#818198'
    }

    const aStyle = {
        color: 'white',
        letterSpacing: '1px',
        fontWeight: 'bold',
        textDecoration: 'none',
        backgroundColor: '#00A9FF',
        padding: '8px 16px',
        marginTop: '20px',
        borderReduis: '4px',
        userSelect: 'none'
    }

    const h3Style = {
        color: '#222222'
    }

    return (
        <div>
            <ul style={listStyle}>
                <li style={liStyle}><p style={pStyle}>Cablesmith has released casestudy on Mukkam smart city </p></li>

                <li style={liStyle}><a style={aStyle} href="https://tinyurl.com/Cablesmith-casestudy" target="_blank" rel="noopener noreferrer">Click Here To Download</a></li>
                <li style={liStyle}><h3 style={h3Style}>Thanks For Downloading</h3></li>
            </ul>
        </div>
    );
}

export default Mailbody;

