import React from 'react'
import './SuccessMessage.scss'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

function SuccessMessage() {

    const navigate = useNavigate()
    return (
        <div className='SentMessage'>
            <div className="container">
                <div className="logo-box">
                    <FaCheckCircle />
                </div>
                <h3>Success</h3>
                <p>PDF Link sent to your mail Please download</p>
                <button onClick={() => navigate('/')} className='btn btn-primary'>Close</button>
            </div>
        </div>
    )
}

export default SuccessMessage