import { Route, Routes } from 'react-router-dom'
import Admin from './pages/admin/Admin';
import Login from './pages/login/Login';
import SendEmail from './pages/sendEmail/SendEmail';
import RequireUser from './componets/RequireUser';
import Mailbody from './componets/mailbody/Mailbody';
import SuccessMessage from './componets/sentmassege/successmessage/SuccessMessage';


function App() {
	return (
		<div className="App">
			<header className="App-header">
				<Routes>

					<Route path='/login' element={<Login />} />
					<Route element={<RequireUser />}>
						<Route path='/admin' element={<Admin />} />
					</Route>

					<Route path='/' element={<SendEmail />} />
					<Route path='/sendEmail' element={<SendEmail />} />
					<Route path='/mailbody' element={<Mailbody />} />
					<Route path='/success' element={<SuccessMessage />} />
				</Routes>
			</header>
		</div>
	);
}

export default App;
