import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

function RequireUser() {

    const userLoggedIn = useSelector(state => state.authReducer.userLoggedIn);
    console.log(userLoggedIn);

    return (
        <div>
            {userLoggedIn ? <Outlet /> : <Navigate to={'/login'} />}
        </div>
    )
}

export default RequireUser