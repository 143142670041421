import React from 'react'
import './Navbar.scss'
import companyLogo from '../../assets/final logo_cableSmith_Horizontal_PNG.png'
import { useNavigate } from 'react-router-dom'

function Navbar() {

    const navigate = useNavigate()

    return (
        <div className='Navbar'>
            <div className="nav-container">
                <div className="logo-container">
                    <div className="img-box">
                        <img src={companyLogo} alt="Cablesmith" />
                    </div>
                </div>
                <div className="left-part">
                    <div className="btn-admin-container">
                        <button className='btn-admin' onClick={() => navigate('/login')}>Admin</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar