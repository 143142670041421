import React from 'react'
import './Header.scss'
import companyLogo from '../../assets/final logo_cableSmith_Horizontal_PNG.png'
import { useNavigate } from 'react-router-dom'

function Header() {
    const navigate = useNavigate();
    return (
        <div className='Header'>
            <div className="header-container">
                <div className='header'>
                    <div className="nav-container">
                        <div className="logo-container">
                            <div className="img-box">
                                <img src={companyLogo} alt="Cablesmith" />
                            </div>
                        </div>
                        <div className="left-part">
                            <div className="btn-admin-container">
                                <button className='btn-close' onClick={() => navigate('/')}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header