import React, { useState } from 'react'
import './Login.scss'
import { useNavigate } from 'react-router-dom'
import { axiosClient } from '../../utils/axiosClient';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/authSlice';
import Header from '../../componets/header/Header';

function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            // cleare both email and password befor making changes 
            setEmailError('')
            setPasswordError('')

            if (!email) {
                setEmailError('Emai is required')
                return;
            }
            if (!password) {
                setPasswordError('Password is required')
                return;
            }
            const result = await axiosClient.post('/auth/login', {
                email,
                password
            });
            // console.log('Axios Result from login api!!!:', result);

            if (result.data.statusCode === 200) {
                // console.log('Successful login. Navigating to /admin');
                // Successful login

                dispatch(loginUser(true))

                navigate('/admin');
            } else {
                // console.log('Unsuccessful login. Setting password error.');
                // Unsuccessful login
                setPasswordError(result.data.message || 'Incorrect password');
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='Login'>
            <Header />
            <div className="email-container">
                <h1 className="main-heading">Admin Login</h1>
                <h4>Please Login to your account</h4>
                <form onSubmit={handleSubmit} className='email-form'>
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input type="email" className="email-input" id="email" name="email" onChange={handleEmailChange} />
                        <div className="error-container">
                            {emailError && <p className='error-message'>{emailError}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="password-input" id="password" name="password" onChange={handlePasswordChange} />
                        <div className="error-container">
                            {passwordError && <p className='error-message'>{passwordError}</p>}
                        </div>
                    </div>
                </form>
                <button onClick={handleSubmit} type="submit" className="btn btn-primary">Submit</button>
            </div>
        </div>
    )
}

export default Login