import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        userLoggedIn: false
    },
    reducers: {
        loginUser: (state, action) => {
            state.userLoggedIn = action.payload
        },
        logoutUser: (state, action) => {
            state.userLoggedIn = action.payload
        }
    }
})

export default authSlice.reducer

export const { loginUser, logoutUser } = authSlice.actions