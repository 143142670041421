import React from 'react';
import './DownloadExcel.scss';
import { axiosClient } from '../../utils/axiosClient';

function DownloadExcel() {
    async function excelFile() {
        try {
            // Make a request to the server to download the Excel file
            const response = await axiosClient.get('/user/downloadExcell', { responseType: 'blob' });

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href to a URL representing the Blob
            link.href = window.URL.createObjectURL(blob);

            // Set the download attribute to specify the file name
            link.download = 'emails.xlsx';

            // Append the link to the document
            document.body.appendChild(link);

            // Trigger a click on the link to start the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className='DownloadExcel'>
                <button className='btn-download' onClick={excelFile}>Download Excel</button>
            </div>
        </>
    );
}

export default DownloadExcel;